/* Global Design */
/* --------------------------------------------------------- */
/* --------------------------------------------------------- */
/* --------------------------------------------------------- */
@font-face {
  font-family: SourceSansPro;
  src: url(fonts/SourceSansPro-Regular.ttf);
}
/* --------------------------------------------------------- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}

:root {
  --primary-color: #0028cc;
  --primary-light-color: #357fff;
  --primary-dark-color: #142749;
  --secondary-color: #652cc7;
  --secondary-light-color: "";
  --secondary-dark-color: "";
}
a {
  text-decoration: none;
  color: unset;
}
ul,
li {
  list-style-type: none;
}
.loading_container {
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}
.loading_spinner {
  display: flex;
}

h1,
h2,
h3,
h4 {
  font-family: system-ui;
  font-size: 35px !important;
}
p,
span,
label {
  font-family: SourceSansPro;
}

body {
  overflow-x: hidden;
}
/* svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: white;
} */
/* svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
  color: white;
}
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-multiline.css-1hof3tc {
  background: white;
} */
.banner {
  /* margin-top: -7%; */
  z-index: -9;
}
.header_left_imgContainer {
  z-index: 9;
}
.header_right {
  z-index: 9;
}
@media only screen and (max-width: 768px) {
  .banner {
    /* margin-top: -21%; */
    z-index: -9;
  }
  .header_right_showMenu {
    z-index: 9;
  }
}
.home_content {
  z-index: 9999 !important;
}
